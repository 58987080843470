<template>
    <auth-page-template>
        <template #default>
            <div class="fl-x fl-j-c mb-5">
                <img src="../../assets/web/auth/email-1.png" alt="">
            </div>
            <div class="text-center">
                <h2 class="fs-lg-6 font-poppins-semibold mb-3 text-center">Password reset link sent to</h2>
                <p class="fs-lg-4 font-poppins-medium mb-31">adventcs@gmail.com</p>
                <p class="fs--2">
                    If you don’t see it in couple of minutes, check whether you entered the correct email and check your
                    spam folder.
                </p>
                <router-link to="/login/" class="text-decoration-none">
                    <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4"
                         text="Return to Login"></btn>
                </router-link>
            </div>
        </template>
    </auth-page-template>
</template>

<script>
export default {
    name: 'PasswordResetLinkComponent'
};
</script>

<style scoped>

</style>
